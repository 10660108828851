import React from 'react'
import styled from 'styled-components'
import { Grid, Wrapper, Image, H, P } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { BORDER_RADIUS, BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'

import PreventOrphan from 'components/PreventOrphan'

const StyledGrid = styled(Grid)`
  align-items: center;

  ${screenMin.l`
    column-gap: ${GTR.XL};
  `}
`

const StyledImage = styled(Image)`
  max-width: 370px;
`

const StyledP = styled(P)`
  max-width: auto;
`

const StyledBackgroundWrapper = styled(Wrapper)<{
  $withBackground: boolean
}>`
  ${({ $withBackground }) =>
    $withBackground &&
    `
    background: ${COLOR.ACCENT.PRIMARY_60};
    position: relative;
    overflow: hidden;

    ${StyledGrid} {
      position: relative;
      background: ${COLOR.WHITE};
      border-radius: ${BORDER_RADIUS.XL};
      padding: ${GTR.XXL} ${GTR.M};
      z-index: 1;

      @media screen and (max-width: ${BREAKPOINT.M}px) {
        padding: ${GTR.L} ${GTR.M};
      }
    }

    ${StyledP} {
      max-width: 460px;
    }
  `}
`

const StyledTextureRightDots = styled(Image)`
  position: absolute;
  right: 0;
  top: 0;
`

const StyledTextureLeftCircle = styled(Image)`
  position: absolute;
  left: -${GTR.XXL};
  bottom: ${GTR.M};
  opacity: 0.3;
`

const StyledTextureLeftSwoop = styled(Image)`
  position: absolute;
  left: -${GTR.M};
  bottom: -${GTR.M};
`

export type ExplainerProps = {
  title: string
  description: string
  imagePath: string
  additionalContent?: React.ReactElement
  descriptionFontSize?: 'S' | 'M' | 'L'
  mobileImagePosition?: 'top' | 'bottom'
  desktopImagePosition?: 'left' | 'right'
  withBackground?: boolean
}

const Explainer = ({
  title,
  description,
  imagePath,
  additionalContent,
  descriptionFontSize = 'M',
  mobileImagePosition = 'top',
  desktopImagePosition = 'left',
  withBackground,
  ...other
}: ExplainerProps): React.ReactElement => (
  <StyledBackgroundWrapper $withBackground={withBackground}>
    {withBackground && (
      <>
        <StyledTextureRightDots
          path="textures/dots-cream"
          width={300}
          loading="auto"
        />
        <StyledTextureLeftCircle
          path="textures/blob-cream-1"
          width={450}
          loading="auto"
        />
        <StyledTextureLeftSwoop
          path="textures/swoop-cream"
          width={600}
          loading="auto"
        />
      </>
    )}
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Wrapper container {...other}>
      <StyledGrid paddingFromM={['M', 0]} paddingFromL={['XL', 0]}>
        <Grid.Item
          padding={['XS', 'M', 'XS', 'XS']}
          paddingFromM={['M']}
          paddingFromL={[0, 0, 0, 'XL']}
          spanFromM={5}
          startColumnFromM={desktopImagePosition === 'left' ? 1 : 7}
          startRow={mobileImagePosition === 'bottom' ? 2 : 1}
          startRowFromM={1}
          centered
        >
          <StyledImage
            path={imagePath}
            width={280}
            widthFromM={340}
            widthFromL={570}
            widthFromXL={700}
            stretch
          />
        </Grid.Item>
        <Grid.Item
          startColumnFromM={desktopImagePosition === 'left' ? 7 : 1}
          spanFromM={6}
        >
          <H size="L" tag="h2" margin={[0, 0, 'S']} decorative>
            <PreventOrphan> {title}</PreventOrphan>
          </H>
          <StyledP size={descriptionFontSize}>{description}</StyledP>
          <Wrapper padding={['M', 0, 0]}>{additionalContent}</Wrapper>
        </Grid.Item>
      </StyledGrid>
    </Wrapper>
  </StyledBackgroundWrapper>
)

export default Explainer
